module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TN8XZC","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Senokot","short_name":"Senokot","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d70727c4573a0f076fff035824bf5af5"},
    },{
      plugin: require('../../gatsby-debug-log/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
